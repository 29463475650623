<template>
  <div class="wrap">
    <div class="filter">
      <div class="get-btn">
        <el-dropdown
          v-if="typeof status === 'number'"
          split-button
          @command="commandHandle"
          type="text"
          :style="{ backgroundColor: colorStatus[status].color }"
        >
          {{ colorStatus[status].type }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              class="dropdown-item"
              :command="index"
              v-for="(item, index) in colorStatus.slice(0)"
              :key="index"
              >{{ item.type }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        v-for="(item, index) in tableData_cell"
        :key="index"
        :prop="item.value"
        :label="item.title"
      ></el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button class="btn" size="mini" @click="redact(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog width="150" title="订单详情" :visible.sync="dialogFormVisible">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="订单详情" name="essentialInformation">
          <el-form :model="formamend">
            <el-form-item label="商品图片：" label-width="120">
              <img style="width: 100px" :src="formamend.img" />
            </el-form-item>
            <el-form-item label="商品名称：" label-width="120">
              {{ formamend.title }}
            </el-form-item>
            <el-form-item label="兑换积分：" label-width="120">
              {{ formamend.jifen }}
            </el-form-item>

            <el-form-item label="姓名：" label-width="120">
              {{ formamend.name }}
            </el-form-item>
            <el-form-item label="电话：" label-width="120">
              {{ formamend.mobile }}
            </el-form-item>
            <el-form-item label="收货地址：" label-width="120">
              {{ formamend.address }}
            </el-form-item>
            <el-form-item label="申请时间：" label-width="120">
              {{ formamend.addtime_str }}
            </el-form-item>

            <el-form-item label="状态：" label-width="120">
              {{ formamend.state_str }}
            </el-form-item>

            <el-form-item label="快递名称：" label-width="120">
              {{ formamend.kuaidi }}
            </el-form-item>

            <el-form-item label="快递单号：" label-width="120">
              {{ formamend.numbers }}
            </el-form-item>

            <el-form-item label="发货时间：" label-width="120">
              {{ formamend.updatetime_str }}
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get_order_user } from "@/api/hr24_api/api_all.js";
import { showCity } from "@/api/resume";
import axios from "axios";

export default {
  data() {
    return {
      search_str: "",

      // 表单数据处理
      tableData_cell: [
        { title: "产品名称", value: "title" },
        { title: "联系人", value: "name" },
        { title: "手机号", value: "mobile" },
        { title: "状态", value: "state_str" },
        { title: "申请时间", value: "addtime_str" },
        { title: "发货时间", value: "updatetime_str" },
      ],
      tableData: [],
      // 编辑
      indry: [],
      city: [],
      is_seed: false,
      dialogFormVisible: false,
      formamend: {
        id: "",
        g_id: "",
        title: "",
        img: "",
        jifen: "",
        name: "",
        mobile: "",
        address: "",
        company_name: "",
        state: "",
        state_str: "",
        addtime_str: "",
        updatetime_str: "",
        kuaidi: "",
        numbers: "",
      },
      place_data: [],
      industry_name: "",
      user_detail_id: "",
      form: {},
      activeName: "essentialInformation",
      //总页码
      pageTotal: null,
      pageNow: 1,

      //审核状态
      colorStatus: [
        { color: "#ccc", type: "审核中" },
        { color: "#FF435A", type: "已发货" },
      ],
      status: 0,
    };
  },
  mounted() {
    this.pageNow = 1;

    get_order_user({
      state: 0,
    }).then((res) => {
      if (!res.code) {
        if (res.data.length) {
          res.data.map((item) => {
            console.log(item);
            // item.img = "../../static/goods/" + item.img;
          });
        }
        console.log("ssssssssssss");
        console.log(res.data);

        this.tableData = res.data;
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
      console.log(res);
    });
  },
  methods: {
    //下拉菜单
    commandHandle(command) {
      console.log(command);

      this.status = command;

      this.search(1);
    },

    getTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    onSubmit() {
      console.log("dddddddddd onSubmit");
      let obj = {
        company_user: this.search_str,
        vip_end_time: this.getTime(this.form.date),
        is_seed: this.form.is_seed == true ? 1 : 0,
      };

      amend_date(obj).then((res) => {
        console.log("dddddddddd amend_date");
        if (!res.code) {
          this.dialogFormVisible = false;
          this.$message.success(res.msg);
          this.search(1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    search(e) {
      console.log("dddddddddd search");

      get_order_user({
        state: this.status,
      }).then((res) => {
        if (!res.code) {
          if (res.data.length) {
            res.data.map((item) => {
              console.log(item);
              // item.img = "../../static/goods/" + item.img;
            });
          }
          console.log("ssssssssssss");
          console.log(res.data);

          this.tableData = res.data;
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        console.log(res);
      });
    },

    redact(e) {
      console.log("sssssssssssss");
      this.activeName = "essentialInformation";
      this.dialogFormVisible = true;
      this.user_detail_id = e.user_detail_id;
      this.formamend = {
        id: e.id,
        g_id: e.g_id,
        title: e.title,
        img: e.img,
        jifen: e.jifen,
        name: e.name,
        mobile: e.mobile,
        address: e.address,
        company_name: e.company_name,
        state: e.state,
        state_str: e.state_str,
        addtime_str: e.addtime_str,
        updatetime_str: e.updatetime_str,
        kuaidi: e.kuaidi,
        numbers: e.numbers,
      };

      console.log(this.formamend);
    },
  },
};
</script>

<style scoped>
.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: auto;
  margin-right: 10px;
}
.el-select > .el-input {
  width: 100px !important;
}
.el-input__inner {
  width: 100px !important;
}
.el-form-item {
  width: 50%;
  float: left;
}

.get-btn {
  float: left;
}

.get-btn::after {
  content: "";
  display: block;
  visibility: hidden;
  height: 0;
  clear: both;
}
</style>
